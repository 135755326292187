/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import "./styles/common/layout.scss"
import { Toast } from "./toast"

const Layout = ({ children, mainClassName }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [emailId, setEmailId] = useState()
  const [isToastVisible, setIsToastVisible] = useState(false)

  const subscribe = emailId => {
    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

    var urlencoded = new URLSearchParams()
    urlencoded.append("emailId", emailId)

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    }

    fetch(
      "https://us-central1-kubric-website.cloudfunctions.net/addSubscriber",
      requestOptions
    )
  }

  return (
    <>
      {isToastVisible && (
        <Toast
          show={isToastVisible}
          title={"Stay Tuned!"}
          imgSrc={
            "https://lh3.googleusercontent.com/WDQcKhaluWYQd5MefqBP12NTBmvhJBqnZHCcDbvC3AFrn2wpOBDz6ls9SjWw0Cl17fTz6EEhkKxin2sHPL4McA"
          }
          description={"You are now subscribed to our newsletter"}
          onHide={setIsToastVisible.bind(undefined, false)}
        ></Toast>
      )}
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className={mainClassName}>{children}</main>
      <footer>
        <div className={"footer inset squish jumbojumbo"}>
          <div className={"divisions"}>
            <div className={"division"}>
              <div className={"name"}>Contact</div>
              <div className={"links"}>
                <a
                  href="mailto:hello@kubric.io?Subject=Hello%20Kubric"
                  target="_blank"
                >
                  Contact Us
                </a>
                <a
                  href="//github.com/cirbuk"
                  target="_blank"
                >
                  Open Source
                </a>
                <a
                  href="//status.kubric.io"
                  target="_blank"
                >
                  Status Page
                </a>
              </div>
            </div>
            <div className={"division"}>
              <div className={"name"}>Product</div>
              <div className={"links"}>
                <Link to="pricing">Pricing</Link>
                <a href="/" target="_top" className={"coming-soon"}>
                  Features
                </a>
                <a href="/" target="_top" className={"coming-soon"}>
                  FAQ
                </a>
              </div>
            </div>
            <div className={"division"}>
              <div className={"name"}>People</div>
              <div className={"links"}>
                <a href="/" target="_top" href="https://kubric.io/blog/">
                  Synapse
                </a>
                <Link to="team">Team</Link>
                <a
                  href="/"
                  target="_top"
                  href="https://angel.co/company/kubric-io"
                >
                  We're hiring!
                </a>
              </div>
            </div>
          </div>
          <div className={"vibe"}>
            <h3>
              Subscribe to
              <strike>
                <span>our blog</span>
              </strike>
            </h3>

            <p>
              The latest updates, news, resources sent straight to your inbox.
            </p>

            <div className={"cta"}>
              <input
                placeholder={"Your email id"}
                onInput={e => {
                  setEmailId(e.target.value)
                }}
              ></input>
              <button
                id={"footer-subscribe-cta"}
                onClick={e => {
                  e.preventDefault()
                  if (emailId) {
                    subscribe(emailId)
                    setIsToastVisible(true)
                  }
                }}
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>

        <div className={"bottom"}>
          <div className={"copyright"}>Copyright @2020 Creative Sparks Inc</div>
          <div className={"sublinks"}>
            <Link to="/termsofuse">Terms of use</Link>
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/security">Security</Link>
          </div>
          <div className={"social"}>
            <a href={"https://www.instagram.com/kubric.io"} target="_blank">
              <img
                loading="lazy"
                loading="lazy"
                loading="lazy"
                className={"icon"}
                src={
                  "https://res.cloudinary.com/dsvdhggfk/image/upload/v1584164876/website-assets-new/landing%20page/Artboard_1_copy_2icon.jpg"
                }
                alt={""}
              ></img>
            </a>
            <a href={"https://twitter.com/kubricHQ"} target="_blank">
              <img
                loading="lazy"
                loading="lazy"
                className={"icon"}
                src={
                  "https://res.cloudinary.com/dsvdhggfk/image/upload/v1584164877/website-assets-new/landing%20page/Artboard_1_copyicon.jpg"
                }
                alt={""}
              ></img>
            </a>
            <a
              href={"https://www.linkedin.com/company/kubric/"}
              target="_blank"
            >
              <img
                loading="lazy"
                loading="lazy"
                className={"icon"}
                src={
                  "https://res.cloudinary.com/dsvdhggfk/image/upload/v1584164877/website-assets-new/landing%20page/Artboard_1icon.jpg"
                }
                alt={""}
              ></img>
            </a>
          </div>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { LogoIcon } from "./logo"
import "../components/styles/common/layout.scss"
import { Hamburger } from "./hamburger"

const Header = ({ siteTitle }) => {
  const [isNavOpen, setNavOpen] = useState(false)

  return (
    <header className={"inset l squish"}>
      <Link to="/">
        <LogoIcon className={"logo"} alt="Kubric Logo" />
      </Link>

      <div
        className={"hamburger"}
        onClick={setNavOpen.bind(undefined, !isNavOpen)}
      >
        <Hamburger isClosed={isNavOpen}></Hamburger>
      </div>

      <nav className={isNavOpen ? "showMobileNav" : ""}>
        <Link to="/" activeClassName={"active-link"}>
          Product
        </Link>
        <Link to="/pricing" activeClassName={"active-link"}>
          Pricing
        </Link>
        <a
          href="https://kubric.io/blog"
          target="_blank"
          className={"scribble"}
        >
          Synapse
        </a>
        <a href="/" href="/team">
          Join the team
        </a>
        <a href="mailto:hello@kubric.io?Subject=Hello%20Kubric" target="_blank" >
          Contact Us
        </a>
      </nav>
      <a href="https://app.kubric.io" target="_blank" className={"linkbutton"}>
        <button>Login</button>
      </a>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

import React from "react"

export const Hamburger = ({ isClosed }) =>
  !isClosed ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 23 21"
    >
      <defs>
        <path id="a" d="M0 0h24v24H0z"></path>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(-1 -2)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a"></use>
        </mask>
        <g fill="#000" mask="url(#b)">
          <path d="M17.92 17.203c.873-.024 1.747.038 2.615.082.788.043 1.498-.034 2.084.566.23.24.35.71.124.998-.518.682-1.075.73-1.876.884-.855.158-1.71.355-2.573.46-1.618.202-3.24.375-4.858.586-3.34.432-6.696 1.176-10.056 1.426-2.222.168-2.693-3.47-.523-3.879 3.326-.624 6.835-.677 10.21-.878 1.617-.096 3.235-.202 4.852-.245zm-.855-7.088c1.762-.173 4.046-.816 5.664-.005 1.291.643 1.07 2.439 0 3.14-.888.58-1.882.475-2.894.494l-2.77.038c-1.78.029-3.562.053-5.342.082-1.79.024-3.562.033-5.348-.02l-2.77-.057c-.791-.014-1.602.02-2.298-.403-.447-.274-.37-.816 0-1.104.681-.523 1.579-.533 2.404-.648.903-.12 1.81-.235 2.712-.37 1.762-.254 3.524-.441 5.3-.614 1.78-.178 3.561-.35 5.342-.533zm3.451-8.08c1.34-.196 2.611.423 2.808 1.873.192 1.406-.864 2.457-2.208 2.548-3.163.216-6.336.336-9.499.456-3.187.12-6.336.255-9.518-.019-1.042-.086-1.388-1.651-.26-1.915 3.077-.72 6.183-1.085 9.308-1.531 3.124-.447 6.244-.95 9.37-1.411z"></path>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 20 21"
    >
      <defs>
        <path id="a" d="M0 0h24v24H0z"></path>
        <path id="c" d="M0 0h19.738v20.97H0z"></path>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(-2 -2)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a"></use>
        </mask>
        <g mask="url(#b)">
          <g transform="translate(2 2)">
            <mask id="d" fill="#fff">
              <use xlinkHref="#c"></use>
            </mask>
            <path
              fill="#000"
              d="M18.73 15.08c-2.481-1.526-4.819-3.264-7.123-5.044a340.87 340.87 0 016.082-7.57C18.841 1.08 16.964-.899 15.673.45a352.423 352.423 0 00-6.816 7.363c-2.045-1.69-4.022-3.441-5.885-5.347C1.868 1.333.227 3.018 1.25 4.19c1.776 2.026 3.662 3.96 5.558 5.875C4.585 12.517 2.324 14.98.366 17.64c-1.469 1.987 1.857 4.58 3.446 2.66 2.007-2.43 3.826-5.031 5.717-7.56 2.146 1.957 4.402 3.796 6.7 5.581.97.754 2.257.86 3.082-.21.759-.98.476-2.377-.58-3.03"
              mask="url(#d)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )

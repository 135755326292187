import React from "react"
import "./styles/common/layout.scss"
import "./styles/common/colors.scss"

export const Toast = ({ show, title, description, imgSrc, onHide }) => (
  <>
    <div className={"overlay"} onClick={onHide}></div>
    <div className={`card toast inset s squish ${show ? "show" : ""}`}>
      <img src={imgSrc} />
      <div>
        <h3>{title}</h3>
        <p>{description}</p>
        <button onClick={onHide}>Back to Website </button>
      </div>
    </div>
  </>
)
